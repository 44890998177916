.wrapper__menu {
  /* display: block; */
  margin-left: -0.5rem;
  margin-top: -1rem;
  transition: 0.5s;
}
.navbar-header {
  padding-left: 0.5rem;
  height: 5em;
}

.close-menu {
  /* display: none; */
  position: fixed;
  min-height: 100vh;
  width: 15%;
  background: #30353a;
  overflow: hidden;
  z-index: 10;
  margin-left: -0.5rem;
  margin-top: -1rem;
  transition: 0.5s;
}
.close-menu {
  display: none;
}

.open-menu {
  /* display: none; */
  position: fixed;
  min-height: 100vh;
  width: 300px;
  background: #30353a;
  overflow: hidden;
  z-index: 10;
  margin-left: -0.5rem;
  margin-top: -1rem;
  transition: 0.5s;
}

.large-screen-side-bar {
  position: fixed;
  width: 60px;
  background: white;
  overflow-x: hidden;
  transition: 0.5s;
  min-height: 100vh;
  z-index: 9999999;
}

.large-screen-side-bar-open {
  min-height: 100vh;
  background: white;
  position: fixed;
  transition: 0.5s;
  width: 220px;
  margin-top: -1rem;
  padding-top: 1rem;
  z-index: 1;
  box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.75);
}

.large-screen-side-bar-open + .navbar-header {
  margin-left: 220px;
  transition: 0.5s;
  height: 5em;
}

.large-screen-side-bar a .nav-link-text {
  display: none;
}

.large-screen-hamburger {
  margin-bottom: 6rem;
  margin-top: 2rem;
}

.profile-icon {
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 28px;
}
.profile-container {
  top: -50%;
  width: 300px;
}
.profile_box {
  width: 200px;
  background: #fff;
  min-height: 180px;
  position: absolute;
  top: 60px;
  right: 5px;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
  z-index: 1;
}

.profile_box a:hover {
  background-color: #276fb4;
  color: #fff;
}

.profile_box:focus,
.notification__box:focus {
  border: none !important;
  outline: none;
}

.notification__box {
  width: 36rem;
  background: #fff;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
  min-height: 400px;
  padding-top: 2rem;
  /* right: 5%; */
  left: 19%;
  top: 60px;
  position: absolute;
  display: block;
  z-index: 1;
}

.notification__box .link-box a {
  border-bottom: 2px solid #ddd;
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #999999;
  text-decoration: none;
}

.notification__box .link-box a.active {
  color: #276fb4;
  border-bottom: 2px solid #276fb4;
  font-weight: 600;
}

.notification-icon {
  cursor: pointer;
}

.notification-number {
  border-radius: 45%;
  padding: 3px 6px;
  top: -10px;
  right: -10px;
}

.wrapper__menu__menu__box {
  color: black;
  align-items: center;
  font-size: 1.3rem;
}

.wrapper__menu__menu__box:hover {
  color: #f68e39 !important;
}

.wrapper__menu__menu__box.wrapper__menu__menu__box--active {
  color: white;
  fill: white;
}

.notification-description {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notification-container {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.notification-title {
  max-width: 70%;
}

.sideNav-v2 {
  display: none;
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 100;
  font-size: 1.1rem;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  -webkit-box-shadow: 1px 0px 5px 0px rgba(161,155,161,1);
  -moz-box-shadow: 1px 0px 5px 0px rgba(161,155,161,1);
  box-shadow: 1px 0px 5px 0px rgba(161,155,161,1);
}

.sideNav-v2-open {
  display: block;
}


.sidebar-open-v2 {
  font-size:3.3rem;
  cursor:pointer;
}

.sideNav-v2 .closeBtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #276FB4;
  text-decoration: none;
}

.sidebar-links {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-divider {
  width: 100%;
  border: 1px solid #d1cfcf;
}
.offcanvas {
  width: 260px !important;
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .open-menu {
    min-height: 100vh;
    width: 80px;
  }
  /* .navbar-header {
    margin-left: 3rem;
  } */
}

@media only screen and (min-width: 1024px) {
  .open-menu {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-header {
    height: 5em;
    margin-left: 2.6rem;
    padding-left: 8rem;
    padding-right: 6.4rem !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media only screen and (min-width: 400px) {
  .notification__box {
    left: 10%;
  }
}

.large-screen-side-bar-open .wrapper__menu__menu .large-screen-hamburger {
  justify-content: flex-end !important;
}

.wrapper__main__header__search {
  top: 10px;
}
